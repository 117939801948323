<!--
 * @Description: 用户注册组件
 * @Author: hai-27
 * @Date: 2020-02-19 22:20:35
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-01 15:34:34
 -->
<template>
  <div id="register">
    <el-dialog  width="496px" center :show-close="false" :visible.sync="isRegister">
      <div slot="title" class="title">
        新用户注册
      </div>
      <div class="ts-lab">填写以下信息，您可以在XPTLab.com网站注册一个新的账户，您登录后需要提供账单
和送货地址信息，所有权限都需要注册后才能便用。 <br /><br />
有关听户的安主性问题，请阅读我们的隐私政策。<br />
</div>
      <el-form
        :model="RegisterUser"
        :rules="rules"
        status-icon
        ref="ruleForm"
        class="demo-ruleForm"
      >
      <!-- <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入姓名"
            v-model="RegisterUser.username"
          ></el-input>
        </el-form-item> -->
        <el-form-item prop="name">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入账号"
            v-model="RegisterUser.name"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            prefix-icon="el-icon-view"
            type="password"
            placeholder="请输入密码"
            v-model="RegisterUser.pass"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPass">
          <el-input
            prefix-icon="el-icon-view"
            type="password"
            placeholder="请再次输入密码"
            v-model="RegisterUser.confirmPass"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="dl-box">
            <div class="dl-btn"  @click="Register">创建一个账户</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { registerApi } from "../api/user.js";
export default {
  name: "MyRegister",
  props: ["register"],
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      // const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      // if (userNameRule.test(value)) {
      //   //判断数据库中是否已经存在该用户名
      //   this.$axios
      //     .post("/api/users/findUserName", {
      //       userName: this.RegisterUser.name
      //     })
      //     .then(res => {
      //       // “001”代表用户名不存在，可以注册
      //       if (res.data.code == "001") {
              this.$refs.ruleForm.validateField("checkPass");
              return callback();
      //       } else {
      //         return callback(new Error(res.data.msg));
      //       }
      //     })
      //     .catch(err => {
      //       return Promise.reject(err);
      //     });
      // } else {
      //   return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
      // }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
          new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    // 确认密码的校验方法
    let validateConfirmPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入确认密码"));
      }
      // 校验是否以密码一致
      if (this.RegisterUser.pass != "" && value === this.RegisterUser.pass) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("两次输入的密码不一致"));
      }
    };
    return {
      isRegister: false, // 控制注册组件是否显示
      RegisterUser: {
        username: "",
        name: "",
        pass: "",
        confirmPass: ""
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        confirmPass: [{ validator: validateConfirmPass, trigger: "blur" }]
      }
    };
  },
  watch: {
    // 监听父组件传过来的register变量，设置this.isRegister的值
    register: function(val) {
      if (val) {
        this.isRegister = val;
      }
    },
    // 监听this.isRegister变量的值，更新父组件register变量的值
    isRegister: function(val) {
      if (!val) {
        this.$refs["ruleForm"].resetFields();
        this.$emit("fromChild", val);
      }
    }
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.$refs["ruleForm"].resetFields();
        this.setShowLogin(val);
      }
    }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    Register() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        //如果通过校验开始注册
        if (valid) {
          let data = {
            username: this.RegisterUser.name,
            password: this.RegisterUser.pass,
            password2: this.RegisterUser.confirmPass,
          }
          registerApi(data)
            .then((res) => {
              // 隐藏注册组件
              this.isRegister = false;
              // 登录信息存到本地
              let userinfo = JSON.stringify(res.data.userinfo);
              localStorage.setItem("token",res.data.userinfo.token)
              localStorage.setItem("user", userinfo);
              // 登录信息存到vuex
              this.setUser(res.data.userinfo);
              // 弹出通知框提示注册成功信息
              this.$message.success(res.msg);
              this.$router.push('/')
            })
            .catch((err) => {
              this.$message.error(err.data.msg);
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
  .title{
    background-color: #CE3720;
    color: #fff;
    height: 40px;
    line-height: 40px;
  }
  .dl-box{

  }
  .dl-btn{
    float: right;
    /* margin: 28px auto 0; */
    width: 120px;
    height: 34px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #D6D6D6;
    text-align: center;
    line-height: 34px;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .ts-lab{
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 12px;
    color: #494949;
    line-height: 18px;
    letter-spacing: 2px;
  }
</style>