/*
 * @Description: 路由配置
 */
import Vue from 'vue';

import Router from 'vue-router';

Vue.use(Router)

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../views/Home.vue"),
	},
	{
		path: "/error",
		name: "Error",
		component: () => import("../components/Error.vue"),
	},
	{
		path: "/goods",
		name: "Goods",
		component: () => import("../views/Goods.vue"),
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
	},
	{
		path: "/goods/details",
		name: "Details",
		component: () => import("../views/Details.vue"),
	},
	{
		path: "/user/login",
		name: "Login",
		component: () => import("../views/user/Login.vue"),
	},
	{
		path: "/user/loginAll",
		name: "Login",
		component: () => import("../views/user/LoginAll.vue"),
	},
	{
		path: "/user/register",
		name: "Register",
		component: () => import("../views/user/Register.vue"),
	},
	{
		path: "/shoppingCart",
		name: "ShoppingCart",
		component: () => import("../views/ShoppingCart.vue"),
		// meta: {
		// 	requireAuth: true, // 需要验证登录状态
		// },
	},
	{
		path: "/collect",
		name: "Collect",
		component: () => import("../views/Collect.vue"),
		meta: {
			requireAuth: true, // 需要验证登录状态
		},
	},
	{
		path: "/order",
		name: "Order",
		component: () => import("../views/Order.vue"),
		// meta: {
		// 	requireAuth: true, // 需要验证登录状态
		// },
	},
	{
		path: "/confirmOrder",
		name: "ConfirmOrder",
		component: () => import("../views/ConfirmOrder.vue"),
		// meta: {
		// 	requireAuth: true, // 需要验证登录状态
		// },
	},
	// 产品中心
	{
		path: "/productContect",
		name: "ProductContect",
		component: () => import("../views/product/productContect.vue"),
	},
	// 产品二级分类
	{
		path: "/productContectChilds",
		name: "ProductContectChilds",
		component: () => import("../views/product/productContectChilds.vue"),
	},
	// 产品三级分类
	{
		path: "/productType",
		name: "ProductType",
		component: () => import("../views/product/productType.vue"),
	},
	// 产品详情
	{
		path: "/productInfo",
		name: "ProductInfo",
		component: () => import("../views/product/productInfo.vue"),
	},
	// 案例分析
	{
		path: "/case",
		name: "Case",
		component: () => import("../views/product/case.vue"),
	},
	// 案例分析详情
	{
		path: "/caseInfo",
		name: "CaseInfo",
		component: () => import("../views/product/caseInfo.vue"),
	},
	// 设计加工详情
	{
		path: "/designInfo",
		name: "DesignInfo",
		component: () => import("../views/product/designInfo.vue"),
	},
	// 用户详情
	{
		path: "/userInfo",
		name: "UserInfo",
		component: () => import("../views/user/UserInfo.vue"),
		meta: {
			requireAuth: true, // 需要验证登录状态
		},
	},
	// 商品详情 productDetail
	{
		path: "/productDetail",
		name: "ProductDetail",
		component: () => import("../views/product/productDetail.vue"),
	},
];

const router = new Router({
  // base: '/dist',
  // mode: 'history',
  routes
})
/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router

