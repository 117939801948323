import request from './request';

/**
 * @description 注册
 */
export function registerApi(data) {
	return request.post(`api/user/register`, data);
}
/**
 * @description 登录
 */
export function loginApi(data) {
	return request.post(`api/user/login`, data);
}
/**
 * @description 验证码
 */
export function captchaApi(data) {
	return request.get(`api/common/captcha`, data);
}
/**
 * @description 找回密码
 */
export function changePwdApi(data) {
	return request.get(`api/user/change_pwd`, data);
}
/**
 * @description 协议
 */
export function agreementApi(data) {
	return request.get(`api/index/agreement`, data);
}
