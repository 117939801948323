/*
 * @Description: Vuex入口
 */
import Vue from 'vue';

import Vuex from 'vuex';

import shoppingCart from './modules/shoppingCart';
import user from './modules/user';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    user,
    shoppingCart,
  }
})
