/*
 * @Description: 用户登录状态模块
 */
export default {
	state: {
		user: "", // 登录的用户
		showLogin: false, // 用于控制是否显示登录组件
		qiye: "", //企业信息
	},
	getters: {
		getUser(state) {
			return state.user;
		},
		getShowLogin(state) {
			return state.showLogin;
		},
		getQiye(state) {
			return state.qiye;
		},
	},
	mutations: {
		setUser(state, data) {
			state.user = data;
		},
		setShowLogin(state, data) {
			state.showLogin = data;
		},
		setQiye(state, data) {
			state.qiye = data;
		},
	},
	actions: {
		setUser({ commit }, data) {
			commit("setUser", data);
		},
		setShowLogin({ commit }, data) {
			commit("setShowLogin", data);
		},
		setQiye({ commit }, data) {
			commit("setQiye", data);
		},
	},
};