<template>
    <div id="myList" class="myList">
        <ul>
            <li class="s" v-for="item in list" :key="item.id">
                <el-collapse-transition>
                    <!-- <el-popover placement="top">
                    <p>确定删除吗？</p>
                    <div style="text-align: right; margin: 10px 0 0">
                        <el-button type="primary" size="mini" @click="deleteCollect(item.id)">确定</el-button>
                    </div>
                    <i class="el-icon-close delete" slot="reference" v-show="isDelete"></i>
                    </el-popover> -->
                    <!-- <router-link :to="{ path: '/goods/details', query: {productID:item.id} }"> -->
                    <!-- <img  alt /> -->
                    <el-image class="liImage" @click="jumpUrl(item)" :src="$target + item.image" fit="contain"></el-image>
                    <!-- <h2>{{item.product_name}}</h2>
                    <h3>{{item.product_title}}</h3>
                    <p>
                        <span>{{item.product_selling_price}}元</span>
                        <span
                        v-show="item.product_price != item.product_selling_price"
                        class="del"
                        >{{item.product_price}}元</span>
                    </p> -->
                    <!-- </router-link> -->
                </el-collapse-transition>

            </li>
            <li style="height: 0;margin:0;"></li>
            <li style="height: 0;margin:0;"></li>
            <li style="height: 0;margin:0;"></li>
            <li style="height: 0;margin:0;"></li>
            <!-- <li v-show="isMore && list.length>=1" id="more">
                <router-link :to="{ path: '/goods', query: {categoryID:categoryID} }">
                浏览更多
                <i class="el-icon-d-arrow-right"></i>
                </router-link>
            </li> -->
        </ul>
    </div>
</template>
<script>
export default {
    name: "MyList",
    // list为父组件传过来的商品列表
    // isMore为是否显示“浏览更多”
    props: ["list", "isMore", "isDelete", "url"],
    data() {
        return {};
    },
    computed: {
        // 通过list获取当前显示的商品的分类ID，用于“浏览更多”链接的参数
        categoryID: function () {
            let categoryID = [];
            if (this.list != "") {
                for (let i = 0; i < this.list.length; i++) {
                    const id = this.list[i].category_id;
                    if (!categoryID.includes(id)) {
                        categoryID.push(id);
                    }
                }
            }
            return categoryID;
        }
    },
    methods: {
        jumpUrl(e){
            //  is_goods
            if (e.is_goods == 1) { //商品
                this.$router.push({path: '/productDetail', query: {id: e.id,name: e.name,categoryid: e.category_id,typeid:3}})
            }else if (e.open_goods == 1) {
                this.$router.push({path: '/productDetail', query: {id: e.id,name: e.name,typeid:4}})
            }else{
                this.$router.push({path: '/productContectChilds', query: {pid: e.id,name: e.name}})
            }
        },
        deleteCollect(product_id) {
            this.$axios
                .post("/api/user/collect/deleteCollect", {
                    user_id: this.$store.getters.getUser.user_id,
                    product_id: product_id
                })
                .then(res => {
                    switch (res.data.code) {
                        case "001":
                            // 删除成功
                            // 删除删除列表中的该商品信息
                            for (let i = 0; i < this.list.length; i++) {
                                const temp = this.list[i];
                                if (temp.product_id == product_id) {
                                    this.list.splice(i, 1);
                                }
                            }
                            // 提示删除成功信息
                            this.notifySucceed(res.data.msg);
                            break;
                        default:
                            // 提示删除失败信息
                            this.notifyError(res.data.msg);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        }
    }
};
</script>
<style scoped>
.myList ul {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.myList ul li {
    position: relative;
    display: block;
    z-index: 1;
    width: 19%;
    height: 200px;
    /* margin: 0 13.7px 14.5px 0; */
    background-color: white;
    line-height: 200px;
    position: relative;
    text-align: center;
    overflow: hidden;
}

.myList ul li .liImage:hover {
    z-index: 2;
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1.1) translate(-48%, -48%);
    transform: scale(1.1) translate(-48%, -48%);
}

.myList ul li .liImage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    border-radius: 0px 0px 0px 0px;
}

.myList ul li h2 {
    margin: 25px 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.myList ul li h3 {
    margin: 5px 10px;
    height: 18px;
    font-size: 12px;
    font-weight: 400;
    color: #b0b0b0;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.myList ul li p {
    margin: 10px 10px 10px;
    text-align: center;
    color: #ff6700;
}

.myList ul li p .del {
    margin-left: 0.5em;
    color: #b0b0b0;
    text-decoration: line-through;
}

.myList #more {
    text-align: center;
    line-height: 280px;
}

.myList #more a {
    font-size: 18px;
    color: #333;
}

.myList #more a:hover {
    color: #ff6700;
}

.myList ul li .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
}

.myList ul li:hover .delete {
    display: block
}

.myList ul li .delete:hover {
    color: #ff6700;
}
</style>