<!--
 * @Description: 登录组件
 -->
<template>
  <div id="myLogin">
    <el-dialog  width="496px" center  :show-close="false" :visible.sync="isLogin">
      <div slot="title" class="title">
        用户登录
      </div>
      <el-form :model="LoginUser" :rules="rules" status-icon ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input prefix-icon="el-icon-user-solid" placeholder="请输入账号" v-model="LoginUser.name"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            prefix-icon="el-icon-view"
            type="password"
            placeholder="请输入密码"
            v-model="LoginUser.pass"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="dl-box">
            <div class="dl-btn"  @click="Login">登录</div>
          </div>
          <!-- <el-button size="medium" type="primary" @click="Login" style="width:100%;">登录</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { loginApi } from "../api/user.js";
export default {
  name: "MyLogin",
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      // const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      // if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      // } else {
      //   return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
      // }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
          new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    return {
      LoginUser: {
        name: "",
        pass: ""
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.$refs["ruleForm"].resetFields();
        this.setShowLogin(val);
      }
    }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    Login() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        //如果通过校验开始登录
        if (valid) {
          let data = {
            account: this.LoginUser.name,
            password: this.LoginUser.pass
          }
          loginApi(data).then(res => {
            // 隐藏登录组件
            this.isLogin = false;
            // 登录信息存到本地
            let userinfo = JSON.stringify(res.data.userinfo);
            localStorage.setItem("token",res.data.userinfo.token)
            localStorage.setItem("user", userinfo);
            // 登录信息存到vuex
            this.setUser(res.data.userinfo);
            // 弹出通知框提示登录成功信息
            this.$message.success(res.msg);
            this.$router.push('/')
          })
          .catch((err) => {
            // 清空输入框的校验状态
            this.$refs["ruleForm"].resetFields();
            this.$message.error(err.data.msg);
            return Promise.reject(err);
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
  .title{
    background-color: #CE3720;
    color: #fff;
    height: 40px;
    line-height: 40px;
  }
  .dl-box{

  }
  .dl-btn{
    margin: 28px auto 0;
    width: 100px;
    height: 34px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #D6D6D6;
    text-align: center;
    line-height: 34px;
    letter-spacing: 3px;
    cursor: pointer;
  }
</style>
<style>
  .el-dialog__header{
    padding:  3px 4px;
  }
</style>