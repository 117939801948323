<!--
 * @Description: 项目根组件
 -->
<template>
    <div id="app" name="app">
        <div>
            <!-- 顶部导航栏 -->
            <!-- <div class="topbar">
                <div class="nav">
                    <ul>
                        <li v-if="!this.$store.getters.getUser">
                            <span>您好，请 </span>
                            <el-button type="text" @click="login">登录</el-button>
                            <span class="sep">|</span>
                            <el-button type="text" @click="register = true">注册</el-button>
                        </li>
                        <li v-else>
                            欢迎
                            <el-popover placement="top" width="180" v-model="visible">
                                <p>确定退出登录吗？</p>
                                <div style="text-align: right; margin: 10px 0 0">
                                    <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                                    <el-button type="primary" size="mini" @click="logout">确定</el-button>
                                </div>
                                <el-button type="text" slot="reference">{{ getUser.nickname }}</el-button>
                            </el-popover>
                        </li>
                    </ul>
                </div>
            </div> -->
            <!-- 顶部导航栏END -->
            <!-- 顶栏容器 -->
            <el-header class="sticky-element">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router>
                    <div class="daohang">
                        <div class="logo" @click="jumpUrl('/')">
                            <!-- <router-link to="/"> -->
                            <img :src="qiyez.logo" alt />
                            <!-- </router-link> -->
                        </div>
                        <div class="so">
                            <input type="text" class="so-input" placeholder="请输入搜索内容" v-model="search">
                            <div class="search-btn" @click="searchClick">
                                <img src="./assets/imgs/search.png" alt="">
                            </div>
                            <!-- <el-input placeholder="请输入搜索内容" v-model="search">
                                <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
                            </el-input> -->
                        </div>
                    </div>
                    <div class="menu-item">
                        <el-menu-item index="/productContect">
                            <div v-on:mouseenter="onMouseEnter" v-on:mouseleave="onMouseLeave"
                                style="height: 100%;display: flex;align-items: center;">产品中心</div>
                        </el-menu-item>
                        <span class="sep">|</span>
                        <el-menu-item index="/case?type=1&typeName=设计加工">设计加工</el-menu-item>
                        <span class="sep">|</span>
                        <el-menu-item index="/case?type=2&typeName=光学中心">光学中心</el-menu-item>
                        <span class="sep">|</span>
                        <el-menu-item index="/about">关于公司</el-menu-item>
                        <span class="sep">|</span>
                        <el-menu-item index="/userInfo" style="padding: 0;">
                            <div class="menu-icon-box s">
                                <img class="menu-icon" src="./assets/imgs/user.png" alt="">
                                <!-- <span>个人中心</span> -->
                            </div>
                        </el-menu-item>
                        <!-- <span class="sep">|</span> -->
                        <el-menu-item index="/shoppingCart" style="padding: 0;">
                            <div class="menu-icon-box-pos" v-on:mouseenter="caronMouseEnter"
                                v-on:mouseleave="caronMouseLeave">
                                <div class="menu-icon-box s" :class="showCar ? 'menu-icon-box-border-car' : ''">
                                    <img class="menu-icon" src="./assets/imgs/shopCar.png" alt="">
                                    <!-- <span>购物车</span> -->
                                </div>
                            </div>
                        </el-menu-item>
                    </div>
                </el-menu>
                <!-- 购物车弹窗 -->
                <div class="carpopup" :class="showCar ? 'carpopupHeight' : ''" v-on:mouseenter="carPPonMouseEnter"
                    v-on:mouseleave="carPPonMouseLeave">
                    <div v-if="getShoppingCart.length > 0" class="carpopupData">
                        <div class="ndgwc">您的购物车</div>
                        <tr class="carpopupData-tr">
                            <td style="width: 30%;">型号</td>
                            <td style="width: 20%;">数量</td>
                            <td style="width: 25%;">单价</td>
                            <td style="width: 25%;">小计</td>
                        </tr>
                        <tr v-for="(item, index) in getShoppingCart" :key="index" class="carpopupData-tr ">
                            <td class="carpopupData-td">{{ item.spec_name }}</td>
                            <td style="width: 20%;">{{ item.num }}</td>
                            <td style="width: 25%;">{{ item.price }}</td>
                            <td style="width: 25%;">{{ item.total_price }}</td>
                        </tr>
                        <div class="submitBtn" @click="submitOrder()">提交订单</div>
                        <!-- <el-button class="submitBtn" type="primary" @click="submitOrder()">提交订单</el-button> -->
                    </div>
                    <div class="empty" v-else>
                        <img class="emptyImg" src="./assets/imgs/kongCar.png" alt="">
                        <span class="emptyText">您的购物车为空，先去逛逛吧</span>
                    </div>
                </div>
                <!-- 产品弹窗 -->
                <div class="popupShadow" :class="isCpOpen ? 'popupShadowHeight' : ''" v-on:mouseover="onMouseOverT"
                    v-on:mouseleave="onMouseOutT">
                    <div class="canpin-box1">
                        <div class="canpin-li" v-for="item in categoryList" :key="item.id">
                            <div class="canpin-li-l-t">{{ item.name }}</div>
                            <div class="canpin-li-r">
                                <div class="canpin-li-r-li" @click.stop="jumpErji(item1)" v-for="item1 in item.cate"
                                    :key="item1.id">
                                    <div class="canpin-li-r-li-p">
                                        <!-- <p class="">{{ item1.name }}</p> -->
                                        <!-- <p>30mm cage system</p> -->
                                    </div>
                                    <div class="canpin-li-r-li-img">
                                        <img :src="$target + item1.show_image" alt="">
                                    </div>
                                </div>
                                <div style="height: 0;"></div>
                                <div style="height: 0;"></div>
                                <div style="height: 0;"></div>
                                <div style="height: 0;"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </el-header>
            <!-- 顶栏容器END -->

            <!-- 登录模块 -->
            <MyLogin></MyLogin>
            <!-- 注册模块 -->
            <MyRegister :register="register" @fromChild="isRegister"></MyRegister>

            <!-- 主要区域容器 -->
            <div class="main">
                <keep-alive>
                    <router-view v-if="RouteState"></router-view>
                </keep-alive>
            </div>
            <!-- 主要区域容器END -->

            <!-- 底栏容器 -->
            <!-- <div class="footer"> -->
            <div :class="[equipment ? 'footer' : 'footers']">
                <div class="ng-promise-box">
                    <img :src="qiyez.logo" alt />
                    <div class="ng-promise">
                        <!-- <div><a :href="getQiye.network_like">公网备案链接</a> </div> -->
                        <div v-html="getQiye.copyright"></div>
                        <!-- <br> {{getQiye.corporate_name}} -->
                    </div>
                </div>
                <div class="mod_help">
                    <p>公司：{{ getQiye.corporate_name }}</p>
                    <p>地址: {{ getQiye.address }}</p>
                    <p>手机: {{ getQiye.phone }}</p>
                    <p>邮箱: {{ getQiye.email }}</p>
                    <p>QQ: {{ getQiye.qq_number }}</p>
                    <p>网址: {{ getQiye.website }}</p>
                </div>
            </div>
            <!-- </div> -->
            <!-- 底栏容器END -->
        </div>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
} from 'vuex';
import { corporateApi, myCarApi, categoryApi } from './api/index'
export default {
    beforeUpdate() {
        this.activeIndex = this.$route.path;
    },
    data() {
        return {
            activeIndex: "", // 头部导航栏选中的标签
            search: "", // 搜索条件
            register: false, // 是否显示注册组件
            visible: false, // 是否退出登录
            isCpOpen: false, //是否显示产品弹窗
            categoryList: [],
            isMouseOverPopup: false, // 鼠标是否移入弹窗
            carisMouseOverPopup: false, // 鼠标是否移入购物车弹窗
            showCar: false,
            shopCarList: [],
            qiyez: {},
            equipment: true,
            RouteState: true
        };
    },
    created() {
        // 获取浏览器localStorage，判断用户是否已经登录
        if (localStorage.getItem("user")) {
            // 如果已经登录，设置vuex登录状态
            this.setUser(JSON.parse(localStorage.getItem("user")));
        }
        this.getCorporate()
        // if (localStorage.getItem("qiye")) {
        //     this.setQiye(JSON.parse(localStorage.getItem("qiye")))
        // } else {
        //     this.getCorporate()
        // }
        this.getCategory()
        myCarApi().then(res => {
            console.log('购物车的数据111', res);
            this.setShoppingCart(res.data);
        })
        this.init()
        // this.getMyShopCar()
    },
    computed: {
        ...mapGetters(["getUser", "getNum", "getQiye", "getShoppingCart"])
    },
    watch: {
        // 获取vuex的登录状态
        getUser: function (val) {
            if (val === "") {
                // 用户没有登录
                this.setShoppingCart([]);
                myCarApi().then(res1 => {
                    console.log('购物车', res1)
                    this.setShoppingCart(res1.data);
                })
            } else {
                // 用户已经登录,获取该用户的购物车信息
                myCarApi().then(res => {
                    console.log('购物车的数据', res);
                    this.setShoppingCart(res.data);
                })
                // this.$axios
                //   .post("/api/user/shoppingCart/getShoppingCart", {
                //     user_id: val.user_id
                //   })
                //   .then(res => {
                //     if (res.data.code === "001") {
                //       // 001 为成功, 更新vuex购物车状态
                //       this.setShoppingCart(res.data.shoppingCartData);
                //     } else {
                //       // 提示失败信息
                //       this.notifyError(res.data.msg);
                //     }
                //   })
                //   .catch(err => {
                //     return Promise.reject(err);
                //   });
            }
        }
    },
    methods: {
        // 判断手机还是pc
        init() {
            const sUserAgent = navigator.userAgent.toLowerCase();
            /* eslint-disable */
            const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
            const bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
            const bIsMidp = sUserAgent.match(/midp/i) == "midp";
            const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
            const bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
            const bIsAndroid = sUserAgent.match(/android/i) == "android";
            const bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
            const bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

            if (
                bIsIpad ||
                bIsIphoneOs ||
                bIsMidp ||
                bIsUc7 ||
                bIsUc ||
                bIsAndroid ||
                bIsCE ||
                bIsWM
            ) {
                this.equipment = false;
            } else {
                this.equipment = true;
            }
            console.log(this.equipment);
        },
        ...mapActions(["setUser", "setShowLogin", "setShoppingCart", "setQiye"]),
        submitOrder() {
            console.log(this.getUser);
            if (!this.getUser) {
                console.log(this.getUser);
                this.$router.push({ path: "/user/LoginAll" });
            } else {
                this.$router.push({ path: "/shoppingCart" });
            }
        },
        // 产品分类
        getCategory() {
            let data = {
                pid: 0,
                limit: 5
            }
            categoryApi(data).then((res) => {
                this.categoryList = res.data;
                console.log(this.categoryList);
            }).catch((res) => {
                this.$message.error(res.message)
            })
        },
        caronMouseEnter() {
            this.showCar = true
        },
        caronMouseLeave() {
            this.showCar = false
        },
        carPPonMouseEnter() {
            this.showCar = true
        },
        carPPonMouseLeave() {
            setTimeout(() => {
                if (!this.carisMouseOverPopup) {
                    // 鼠标移出容器并且没有移入弹窗，隐藏弹窗
                    this.showCar = false;
                }
            }, 30)
        },
        // 移入产品中心
        onMouseEnter() {
            this.isCpOpen = true
        },
        // 移除产品中心
        onMouseLeave() {
            // 缓解时间确定鼠标是否移入弹窗
            setTimeout(() => {
                if (!this.isMouseOverPopup) {
                    // 鼠标移出容器并且没有移入弹窗，隐藏弹窗
                    this.isCpOpen = false;
                }
            }, 30)
        },
        // 移入弹窗
        onMouseOverT() {
            this.isMouseOverPopup = true;
        },
        // 移除弹窗
        onMouseOutT() {
            this.isMouseOverPopup = false;
            this.isCpOpen = false

        },

        // 跳转产品中心
        jumpErji(e) {
            this.isCpOpen = false
            this.RouteState = false;
            this.$nextTick(() => {
                this.RouteState = true;
            });
            if (e.is_goods == 1) { //商品
                this.$router.push({ path: '/productDetail', query: { id: e.id, name: e.name, categoryid: e.category_id, typeid: 3 } })
            } else if (e.open_goods == 1) {
                this.$router.push({ path: '/productDetail', query: { id: e.id, name: e.name, typeid: 4 } })
            } else {
                this.$router.push({ path: '/productContectChilds', query: { pid: e.id, name: e.name } })
            }
        },
        jumpUrl(url) {
            this.$router.push(url)
        },
        login() {
            // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
            this.setShowLogin(true);
        },
        // 企业信息 
        getCorporate() {
            corporateApi().then(res => {
                this.qiyez = res.data
                console.log(res.data, 999);
                localStorage.setItem("qiye", JSON.stringify(res.data))
                this.setQiye(res.data)
            })
        },
        // 退出登录
        logout() {
            this.visible = false;

            // 清空本地登录信息
            localStorage.setItem("user", "");
            // 清空vuex登录信息
            this.setUser("");
            this.notifySucceed("成功退出登录");
        },
        // 接收注册子组件传过来的数据
        isRegister(val) {
            this.register = val;
        },
        // 点击搜索按钮
        searchClick() {
            if (this.search != "") {
                // 跳转到全部商品页面,并传递搜索条件
                this.$router.push({ path: "/goods", query: { search: this.search } });
                this.search = "";
            }
        }
    }
};
</script>

<style>
tr {
    padding: 6px 0;
    box-sizing: border-box;
}

.s {
    cursor: pointer;
}

/* 对于垂直滚动条 */
::-webkit-scrollbar {
    height: 0;
    /* Chrome、Safari等WebKit内核浏览器支持 */
}

/* 全局CSS */
* {
    padding: 0;
    margin: 0;
    border: 0;
    list-style: none;
}

#app .el-header {
    height: 3.75vw !important;
    /* padding: 20px 0; */
    /* position: relative; */
}

#app .el-main {
    /* min-height: 300px; */
    /* overflow: hidden; */
    padding: 0;
    background-color: #f6f6f6;
}

#app .el-footer {
    padding: 0;
}

a,
a:hover {
    text-decoration: none;
}

.el-menu::after,
.el-menu::before {
    display: none;
}

/* 全局CSS END */
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgba(253, 244, 245, .8);
    color: #CE3720;
    border: 1px solid #CE3720;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #CE3720;
    box-sizing: border-box;
}

/* 顶部导航栏CSS */
.topbar {
    height: 40px;
    background-color: #f6f6f6;
    /* margin-bottom: 20px; */
}

.topbar .nav {
    width: 1250px;
    margin: 0 auto;
}

/* 在横向布局且屏幕宽度小于或等于 800px 时应用的 CSS 样式 */
@media screen and (max-width: 1920px) and (orientation: landscape) {}

.topbar .nav ul {
    /* float: left; */
}

.topbar .nav li {
    /* float: left; */
    height: 40px;
    color: #b0b0b0;
    font-size: 14px;
    text-align: left;
    line-height: 40px;
    margin-left: 20px;
}

.topbar .nav .sep {
    color: #b0b0b0;
    font-size: 12px;
    margin: 0 5px;
}

.topbar .nav li .el-button {
    color: #CE3720;
}

.topbar .nav .el-button:hover {
    color: #ff0000;
}

.topbar .nav li a {
    color: #b0b0b0;
}

.topbar .nav a:hover {
    color: #fff;
}

.topbar .nav .shopCart {
    width: 120px;
    background: #424242;
}

.topbar .nav .shopCart:hover {
    background: #fff;
}

.topbar .nav .shopCart:hover a {
    color: #ff6700;
}

.topbar .nav .shopCart-full {
    width: 120px;
    background: #ff6700;
}

.topbar .nav .shopCart-full a {
    color: white;
}

.sticky-element {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fff;
    /* position: relative; */
    display: flex;
    align-items: center;
    height: 100px;
}

/* 顶部导航栏CSS END */

/* 顶栏容器CSS */
.el-header .el-menu {
    width: 1250px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 900px) {
    .el-header .el-menu {
        width: 90% !important;
    }
}

.el-menu.el-menu--horizontal {
    border: none !important;
}

.el-menu-demo {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
}

.daohang {
    position: relative;
    display: flex;
    width: 614px;
    /* justify-content: space-between; */
}

.el-header .logo {
    /* height: 40px; */
    /* line-height: 40px; */
    width: 256px;
    cursor: pointer;
    margin-right: 80px;
    vertical-align: middle;
}

.el-header .logo img {
    width: 100%;
    vertical-align: middle;
}

.el-header .so {
    margin-top: 6px;
    position: relative;
    width: 230px;
    height: 42px;
    line-height: 40px;
    border: 1px solid #F7F7F7;
    border-radius: 30px;
    box-sizing: border-box;
}

.el-header .so .so-input {
    width: 210px;
    height: 39px;
    font-size: 14px;
    outline: none;
    vertical-align: middle;
}

.el-header .so .search-btn {
    position: absolute;
    right: 0px;
    top: 1px;
    width: 40px;
    height: 40px;
    background: #F7F7F7;
    border-radius: 24px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;

}

.el-header .so .search-btn img {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    margin-top: -6px;
}

.el-header .menu-item {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.el-header .menu-item .el-menu-item {
    height: 100%;
    display: flex;
    align-items: center;
    color: #9D9A96;
    /* font-size: 1.5vw; */
    font-size: 20px;
    padding: 0 .625vw;
}

.el-header .menu-item .sep {
    /* padding-top: 19px; */
    color: #b0b0b0;
    font-size: 18px;
    margin: 0 5px;
}

.menu-icon-box {
    /* padding: 0 20px; */
    height: 40px;
    /* border: 1px solid #D5D5D5; */
    margin-left: 16px;
    position: relative;
    /* min-width: 100px; */
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;

}

.menu-icon-box:hover {
    color: #d15400;
    /* border-bottom: none; */
}

.ndgwc {
    width: 100%;
    text-align: left;
    padding-left: 10px;
    font-size: 1.5vw;
}

.submitBtn {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #BE3C28;
    color: #FFFFFF;
    margin-top: 40px;
    width: 90%;
    cursor: pointer;
    border-radius: 35px 35px 35px 35px;
    font-size: 1.4vw;
}

.carpopup {
    position: absolute;
    top: 100px;
    right: 0;
    margin: 0 auto;
    width: 400px;
    height: 0;
    background-color: #fff;
    /* box-sizing: border-box; */
    /* box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.16); */
    transition: all .4s cubic-bezier(.4, 0, .2, 1) 0s;
    overflow-y: auto;
    border-radius: 0 0 10px 10px;
    /* z-index: -1; */
}

.carpopupHeight {
    height: 460px;
}

.carpopupData {
    padding: 0 20px 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emptyImg {
    margin-top: 100px;
    width: 50px;
    height: 50px;
}

.emptyText {
    margin-top: 20px;
    font-size: 16px;
    color: rgb(157, 154, 150);
}

.menu-icon-box span {
    margin-left: 5px;
    font-size: 16px;
}

.menu-icon-box-pos {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}



/* .carpopup::after {
    content: '';
    position: absolute;
    top: -20px;
    right: 0;
    border-left: 30px solid transparent;
    border-bottom: 30px solid pink;
} */

.el-header .menu-item .menu-icon {
    width: 1.45vw;
    height: 1.45vw;
}

.el-menu-item:focus,
.el-menu-item:hover {
    background: transparent;
}

.popupShadow {
    position: absolute;
    overflow-y: scroll;
    top: 3.75vw;
    width: 102%;
    left: 0;
    right: 0;
    height: 0;
    margin: auto;
    /* background-color: rgba(0, 0, 0, .3); */
    transition: all 1s cubic-bezier(.4, 0, .2, 1) 0s;
    z-index: -1;
}

.popupShadowHeight {
    padding: 0px 0;
    box-sizing: border-box;
    height: 610px;
}

.canpin-box1 {
    /* display: none; */
    /* position: absolute; */
    /* top: 100px; */
    left: 0;
    right: 0;
    margin: auto;
    padding-bottom: 20px;
    background: #FFFFFF;
    z-index: 10020;
}

.canpin-box1 .canpin-li {
    max-width: 1250px;
    margin: 0 auto 13px;
    display: flex;
}

.canpin-box1 .canpin-li .canpin-li-l-t {
    width: 40px;
    background: #BE3C28;
    color: #FFFFFF;
    opacity: 0.9;
    font-family: "惠普字体Medium";
    font-size: 20px;
    writing-mode: vertical-rl;
    /* 从下往上的竖向排列 */
    text-orientation: upright;
    /* 保持文字正立 */
    white-space: nowrap;
    /* 防止文字换行 */
    text-align: center;
    /* padding-right: 10px; */
    padding: 10px 10px 10px 0;
}

.canpin-box1 .canpin-li .canpin-li-r {
    width: 97%;
    display: flex;
    /* justify-content: space-between; */
}

.canpin-box1 .canpin-li .canpin-li-r-li {
    margin-right: 10px;
    cursor: pointer;
    background: #F7F7F7;
    width: 19%;
    display: flex;
    justify-content: space-between;
}

.canpin-box1 .canpin-li .canpin-li-r-li-p {
    padding-top: 54px;
}

.canpin-box1 .canpin-li .canpin-li-r-li-p p {
    padding-left: 20px;
    font-size: 14px;
}

.canpin-box1 .canpin-li .canpin-li-r-li-img {
    /* padding: 24px 20px 24px 30px ; */
    width: 100%;
    height: 140px;
}

.canpin-box1 .canpin-li .canpin-li-r-li-img img {
    width: 100%;
    height: 100%;
}

.carpopupData-tr {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 16px;
    border-top: 1px solid #9D9A96;
}

.carpopupData-td {
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 2px;
}

/* 顶栏容器CSS END */

/* 底栏容器CSS */
.footer {
    padding-top: 40px;
    padding-bottom: 100px;
    text-align: center;
    background: #fff;
    display: flex;
    /* justify-content: space-between;
    left: 0;
    right: 0;*/
    margin: 0 auto;
    max-width: 1250px;
    display: flex;
    justify-content: space-between;
}

.footer .ng-promise-box {
    text-align: left;
    width: 700px;
    /* padding-left: 99px; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 20px;
}

.footer .ng-promise-box img {
    width: 256px;
    height: 53px;
}

.footer .ng-promise-box .ng-promise {
    /* width: 70%; */
    font-family: "惠普字体";
    font-weight: normal;
    font-size: 16px;
    color: #A5A29E;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.footer .mod_help {
    font-family: "惠普字体";
    width: 20vw;
    text-align: left;
    color: #888888;
    font-size: 16px;
}

.footer .mod_help p {
    margin: 20px 0 16px 0;
}

.footer .mod_help p a {
    color: #888888;
    text-decoration: none;
}

.footer .mod_help p a:hover {
    color: #fff;
}

.footer .mod_help p span {
    padding: 0 22px;
}

.footers {
    padding-top: 40px;
    padding-bottom: 100px;
    text-align: center;
    background: #fff;
    display: flex;
    /* justify-content: space-between;
    left: 0;
    right: 0;*/
    margin: 0 auto;
    max-width: 77%;
    display: flex;
    justify-content: space-between;
}

.footers .ng-promise-box {
    text-align: left;
    width: 70%;
    /* padding-left: 99px; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 20px;
}

.footers .ng-promise-box img {
    width: 256px;
    height: 53px;
}

.footers .ng-promise-box .ng-promise {
    /* width: 70%; */
    font-family: "惠普字体";
    font-weight: normal;
    font-size: 16px;
    color: #A5A29E;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

.footers .mod_help {
    font-family: "惠普字体";
    width: 20vw;
    text-align: left;
    color: #888888;
    font-size: 16px;
}

.footers .mod_help p {
    margin: 20px 0 16px 0;
}

.footers .mod_help p a {
    color: #888888;
    text-decoration: none;
}

.footers .mod_help p a:hover {
    color: #fff;
}

.footers .mod_help p span {
    padding: 0 22px;
}

/* 底栏容器CSS END */
.el-breadcrumb {
    font-size: 14px !important;
}
</style>